export const environment = {
    appApiUrl: 'https://crm.neos.eu/api/app/v1',
    appUrl: 'https://go.neos.eu',
    entityId: null,
    environment: 'prod',
    client: 'neos',
    faqSource : 'https://app-data.neos.eu/faq.json',
    firebaseAccount: 'neos-prod',
    firebaseApiKey: 'AIzaSyD4Ho-VwerP31eF1-hzkr6qd4xPZg9WrAE',
    firebaseAppId: '1:681037541924:web:d957be04792041344e1eb1',
    firebaseAuthDomain: 'neos-2-go-c0991.firebaseapp.com',
    firebaseDatabaseURL: 'https://neos-2-go-c0991.firebaseio.com',
    firebaseMessagingSenderId: '681037541924',
    firebaseProjectId: 'neos-2-go-c0991',
    firebaseStorageBucket: 'neos-2-go-c0991.appspot.com',
    googleMapsApiKey: 'AIzaSyD4Ho-VwerP31eF1-hzkr6qd4xPZg9WrAE',
    language: 'de',
    notificationsEnabled: true,
    preferredCountryCodes: ['AT', 'DE', 'CH'],
    privacyTermsUrl : 'https://www.neos.eu/datenschutz',
    production: true,
    provinceFilterEnabled: true,
    publicApiUrl: 'https://crm.neos.eu/api/public/v1',
    releaseVersion: '54.3',
    sentryDsn: 'https://11d56fafe5bc4037bb67f6856dd0f356@sentry.webmozarts.com/11',
    sentryEnvironment: 'prod',
    sentryShowErrorDialog: false,
    sentryTracesSampleRate: 0.2,
    sentryRelease: '2go@54.3',
    validatedCountryCode: 'AT',
    defaultLongitude: 13.539224,
    defaultLatitude: 47.704637,
    defaultZoomLevel: 11,
    defaultMapRadius: 15,
    defaultListRadius: 100,
    phoneNumberMandatory: true,
    salutationMandatory: true,
    appProfileAddressLines: true,
    feedbackEmail: 'neos2go@neos.eu',
    twitterIntegrationEnabled: true,
    twitterUsername: 'neos_eu',
    facebookIntegrationEnabled: true,
    facebookAppId: '417766522303182',
    appLinkTracking: null,
};
